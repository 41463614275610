;(function(){
    
    var func = {
        scrollSpyPage: function(elem, target) {
            return $(elem).scrollspy({
                target: target,
                offset: 50
            });
        },
        scrollNav: function(elem) {
            return $(elem).on("click", function(event) {
                if (this.hash !== "") {
                    event.preventDefault();

                    var hash = this.hash;

                    $('html, body').animate({
                        scrollTop: $(hash).offset().top
                    }, 1000);
                }
            });
        },
        sendEmail: function() {
            return $("form[name='getIntrosignForm']").submit(function(e) {
                e.preventDefault();

                var $form = $(this),
                    $email = $form.find('input[type=email]'),
                    $alert = $form.find('.alert'),
                    $submitBtn = $(this).find('button[type=submit]');

                if (!func.validateEmail($email.val())) {
                    $email.focus().next("p").removeClass("sr-only");
                    return false;
                }

                $submitBtn.button('loading');

                $.ajax({
                    type: "POST",
                    url: $form.attr("action"),
                    data: $form.serialize(),
                    dataType: "json",
                    beforeSend: function() {

                        $form.find('input, textarea').attr('disabled', true);

                    },
                    success: function(data) {

                        var result = data["result"],
                            msg = data['message'];

                        $alert.removeClass("sr-only alert-success alert-danger");

                        if (result == "success") {
                            $alert.addClass('alert-success');
                        } else {
                            $alert.addClass('alert-danger');
                        }

                        $alert.html(data['message']);
                    },
                    error: function() {
                        console.log('something went wrong...');
                    }
                })
                .done(function(){
                    setTimeout(function(){
                        $email.next("p").addClass("sr-only");
                        $form.find("input, textarea").attr("disabled", false).val("").text("");
                        $submitBtn.button('reset');
                    }, 3000);
                    setTimeout(function(){ $alert.empty().addClass("sr-only"); }, 6000);
                });
                
            });
        },
        validateEmail: function(email) {
            var regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regExp.test(email);
        }
    };

    func.scrollSpyPage('body', '.navbar');
    func.scrollNav('.navbar a');
    func.sendEmail();

}(jQuery, window, document));